import RestoreIcon from "@mui/icons-material/Restore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Box,
  Menu,
  MenuItem,
  Grid,
  styled,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { MarketMoveStoreListSchema } from "../../../../schema/schemas";
import { useFormStore } from "../../../../store/formStore";
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CancelationTable from "../CancelationTable";
import useMarketMoveStore from "../../../../store/marketMoveStore";
import { useMarketMoveCancel } from "../../../../hooks/useMarketMoveCancel";

interface StoreListDialogProps {
  storeList: MarketMoveStoreListSchema[];
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  table: any;
  resetStoreList: () => void;
}

const StoreListDialog: React.FC<StoreListDialogProps> = ({
  storeList,
  open,
  setOpen,
  onClose,
  table,
  resetStoreList,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [cancelMarketMoveDialog, setCancelMarketMoveDialog] = useState(false);
  const [cancelPreviewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);
  const totalCount = storeList.length;
  const { marketMoveMetaDataMethods, marketMoveRunMethods } = useFormStore();
  const { disableEditing  } = useMarketMoveStore();
  const selectedCount =
    storeList?.filter((store) => store.includeFlag).length || 0;

  const { cancelMarketMove } = useMarketMoveCancel({
    onSuccess: () => {
      setCancelMarketMoveDialog(false);
      setPreviewDialogOpen(false);
    },
  });

  const getButtonText = () => {
    if (selectedCount === 0) return `${totalCount} Stores`;
    return `Store List (${selectedCount} / ${totalCount})`;
  };

  const getTooltipText = () => {
    if (selectedCount === 0)
      return "There are no stores selected but there are stores available.";
    return `${selectedCount} out of ${totalCount} available stores selected`;
  };

  const handleClose = async () => {
    onClose();
    setOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelWholeMarketMove = () => {
    console.log("Canceling whole market move");
    setCancelMarketMoveDialog(true);
    handleMenuClose();
  };

  const handleCancelOneStore = () => {
    console.log("Canceling one store from market move");
    setPreviewDialogOpen(true);
    handleMenuClose();
  };

  const handleCancelDialogClose = () => {
    setCancelMarketMoveDialog(false);
  }

  const handleSelectionChange = useCallback((newSelectedStoreIds: string[]) => {
    setSelectedStoreIds(newSelectedStoreIds);
  }, []);

  useEffect(() => {
    console.log("Selected store IDs:", selectedStoreIds);
  }, [selectedStoreIds]);

  const handleConfirmCancel = async () => {
    enqueueSnackbar('Request sent to Cancel Whole Market Move... Please Wait.', {variant: 'info'});
    await cancelMarketMove('CANCELMM');
  };

  const handlePreviewSubmit = async () => {
    enqueueSnackbar('Request sent to remove selected stores from Market Move... Please wait.', { variant: 'info' });
    await cancelMarketMove('CANCELSTORES', selectedStoreIds);
  };

  const handlePreviewDialogClose = () => {
    setPreviewDialogOpen(false);
  }

  const StyledDialogActions = styled(DialogActions)({
    padding: '0px 0px', 
    justifyContent: 'flex-end',
  });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Tooltip title={getTooltipText()}>
          <span>
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              color={selectedCount === 0 ? "secondary" : "primary"}
            >
              {getButtonText()}
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Market Move Cancel Options">
        <span>
            <IconButton
              onMouseEnter={handleMenuOpen}
              disabled={!disableEditing}
            >
              <MoreVertIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{onMouseLeave: handleMenuClose}}
      >
        <MenuItem onClick={handleCancelWholeMarketMove}>Cancel Whole Market Move</MenuItem>
        <MenuItem onClick={handleCancelOneStore}>Cancel Store(s) from Market Move</MenuItem>
      </Menu>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Selected Stores</DialogTitle>
        <DialogContent>
          <MaterialReactTable table={table} />
        </DialogContent>
        <DialogActions>
          <Tooltip title="Reset Changes">
            <span>
              <IconButton onClick={resetStoreList}>
                <RestoreIcon />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelMarketMoveDialog}
        onClose={handleCancelDialogClose}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">Confirm Cancellation of Whole Market Move</DialogTitle>
        <DialogContent>
          <DialogContent id="cancel-dialog-description">
            Are you sure you want to cancel the entire market move? This action cannot be undone.
          </DialogContent>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleConfirmCancel} color="primary" variant={'contained'}>
            Yes, Cancel
          </Button>
          <Button onClick={handleCancelDialogClose} color="error" variant={'contained'}>
            No, Do Not Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelPreviewDialogOpen}
        onClose={handlePreviewDialogClose}
        fullWidth
        maxWidth="lg"
      >
          <DialogTitle>Select Included Stores to Remove</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CancelationTable onSelectionChange={handleSelectionChange}/>
            </Grid>
            <Grid item>
              <StyledDialogActions>
                <Button onClick={handlePreviewDialogClose} color="primary" variant="contained">
                  Remove Stores
                </Button>
                <Button onClick={handlePreviewSubmit} color="error" variant="contained">
                  Discard Changes
                </Button>
              </StyledDialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StoreListDialog;