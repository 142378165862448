import { Box } from "@mui/material";
import React from "react";

interface TableToolbarProps {
  allSelected: boolean;
  handleToggleAll: () => void;
  storeDataLength: number;
}

  const StoreListTableToolbar: React.FC<TableToolbarProps> = ({
  allSelected,
  handleToggleAll,
  storeDataLength,
}) => (
  <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
    {/* <Tooltip title={allSelected ? "Deselect All Stores" : "Select All Stores"}>
      <IconButton onClick={()=>handleToggleAll()}>
        {allSelected ? (
          <CheckCircleOutlineIcon />
        ) : (
          <RadioButtonUncheckedIcon />
        )}
      </IconButton>
    </Tooltip>
    <Tooltip
      title={`Sync store list with selected stores from ListView. (${storeDataLength} stores)`}
    >
      <span>
        <IconButton
          // onClick={updateInclusionStoreList}
          // disabled={isUpdateDisabled}
        >
          <ImportExportIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Tooltip title="Reload store list from server">
      <IconButton 
      // onClick={refetch} disabled={isLoading || isFetching}
      >
        <RefreshIcon />
      </IconButton>
    </Tooltip> */}
  </Box>
);

export default StoreListTableToolbar;