import {
  AccessTime,
  ArrowBack,
  CalendarToday,
  ExpandLess,
  ExpandMore,
  Store,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { RunStatus } from "../../../interfaces/enums";
import {
  MarketMoveRunDetailSchema,
  MarketMoveRunSchema,
} from "../../../schema/schemas";
import { useFormStore, useMarketMoveRunMethods } from "../../../store/formStore";
import useGeneralStore from "../../../store/generalStore";
import useMarketMoveStore from "../../../store/marketMoveStore";
import { calculateDuration, formatLocalDate } from "../../../utils/textUtils";
import ProductTable from "../../previewTable/PreviewTable";
import ProductCard from "./ProductCard";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

const MarketMoveCard: React.FC<{
  selectedMarketMove: MarketMoveRunSchema;
  recapDetails: MarketMoveRunDetailSchema[];
}> = ({ selectedMarketMove: selectedMarketMoveRun, recapDetails }) => {
  const { modifiedStoresCount, storesLeftOut } = useMemo(() => {
    const allStores = new Set(recapDetails.map(detail => detail.storeId));
    const modifiedStores = new Set(
      recapDetails
        .filter((detail) => detail.includeFlag)
        .map((detail) => detail.storeId)
    );
    
    return {
      modifiedStoresCount: modifiedStores.size,
      totalStoresCount: allStores.size,
      storesLeftOut: allStores.size - modifiedStores.size
    };
  }, [recapDetails]);

  return (
    <Card variant="outlined">
      <CardContent sx={{ "& > *": { mb: 3 } }}>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
          Market Move: {selectedMarketMoveRun.marketMoveRun?.marketMoveName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarToday sx={{ mr: 2, color: "text.secondary" }} />
            <Box>
              <Typography variant="body2">Start Date:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {selectedMarketMoveRun.marketMoveRun?.startDate
                  ? formatLocalDate(selectedMarketMoveRun.marketMoveRun.startDate)
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarToday sx={{ mr: 2, color: "text.secondary" }} />
            <Box>
              <Typography variant="body2">End Date:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {selectedMarketMoveRun.marketMoveRun?.endDate
                  ? formatLocalDate(selectedMarketMoveRun.marketMoveRun.endDate)
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "48px",
          }}
        >
          <AccessTime sx={{ mr: 2, color: "text.secondary" }} />
          <Typography variant="body1">
            Duration:{" "}
            <strong>
              {selectedMarketMoveRun.marketMoveRun?.startDate &&
              selectedMarketMoveRun.marketMoveRun?.endDate
                ? calculateDuration(
                    selectedMarketMoveRun.marketMoveRun.startDate,
                    selectedMarketMoveRun.marketMoveRun.endDate
                  )
                : "N/A"}
            </strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            bgcolor: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Store sx={{ mr: 2, color: "text.secondary" }} />
            <Typography variant="body1">
              Stores affected: <strong>{modifiedStoresCount}</strong>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Store sx={{ mr: 2, color: "text.secondary" }} />
            <Typography variant="body1">
              Stores unaffected: <strong>{storesLeftOut}</strong>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const Header: React.FC<{ handleSubmit: () => void }> = ({ handleSubmit }) => {
  const { toggleRecap } = useGeneralStore();
  const { isPreviewStale, disableEditing } = useMarketMoveStore();
  const { control: marketMoveRunControl } = useMarketMoveRunMethods();
  const selectedMarketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });

  const [dialogOpen, setDialogOpen] = useState(false);


  const disableSubmit = disableEditing || 
    selectedMarketMoveRun.marketMoveRun.runStatus === RunStatus.SIGNEDOFF ||
    selectedMarketMoveRun.marketMoveRun.runStatus === RunStatus.INPROGRESS;

  const getTooltipMessage = () => {
    if (selectedMarketMoveRun.marketMoveRun.runStatus === RunStatus.INPROGRESS) return "Market Move Run is in progress.";
    if (selectedMarketMoveRun.marketMoveRun.runStatus === RunStatus.SIGNEDOFF) return "Market Move Run is already signed off.";
    if (disableEditing) return "Editing is currently disabled.";
    if (isPreviewStale) return "Preview is stale. Please refresh.";
    return "";
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onConfirmSubmit = () => {
    handleSubmit();
    closeDialog();
  }

  return (
    <>
    <Grid container alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={1}>
        <IconButton color="success" onClick={toggleRecap}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid item xs={10} container justifyContent="center">
        <Typography variant="h4" color="success.main" fontWeight="bold">
          Recap
        </Typography>
      </Grid>
      <Grid item xs={1} container justifyContent="flex-end">
        <Tooltip title={disableSubmit ? getTooltipMessage() : ""}>
          <span>
            <Button
              variant="contained"
              color="success"
              disabled={disableSubmit}
              onClick={openDialog}
            >
              Submit
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
    <SubmitConfirmationDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSubmit={onConfirmSubmit}
        startDate={selectedMarketMoveRun.marketMoveRun?.startDate}
        endDate={selectedMarketMoveRun.marketMoveRun?.endDate}
      />
    </>
  );
};

const Recap: React.FC = React.memo(() => {
  const [isProductBreakdownExpanded, setIsProductBreakdownExpanded] =
    useState(true);

  const {
    marketMoveRunMethods: { control },
  } = useFormStore();

  const selectedMarketMoveRun = useWatch<MarketMoveRunSchema>({ control });
  const { incrementMMRunSaveRequest } = useMarketMoveStore();

  useEffect(() => {
    console.debug("selectedMarketMoveRun", selectedMarketMoveRun);
  }, [selectedMarketMoveRun]);

  if (!selectedMarketMoveRun) {
    return (
      <Box>
        <Typography>No Market Move selected.</Typography>
      </Box>
    );
  }

  const typedRecapDetails: MarketMoveRunDetailSchema[] = 
    selectedMarketMoveRun.marketMoveRun.runStatus === RunStatus.INPROGRESS
      ? selectedMarketMoveRun.marketMoveRunDetails || []
      : selectedMarketMoveRun.marketMoveRunRecapDetails || [];

  const toggleProductBreakdown = () => {
    setIsProductBreakdownExpanded(!isProductBreakdownExpanded);
  };

  const handleSubmit = () => {
    incrementMMRunSaveRequest(RunStatus.SIGNEDOFF);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Header handleSubmit={handleSubmit} />
      <Divider sx={{ mb: 2 }} />
      <MarketMoveCard
        selectedMarketMove={selectedMarketMoveRun}
        recapDetails={typedRecapDetails}
      />
      <Box sx={{ mt: 3 }}>
        <Button
          onClick={toggleProductBreakdown}
          startIcon={
            isProductBreakdownExpanded ? <ExpandLess /> : <ExpandMore />
          }
          sx={{ mb: 1 }}
        >
          <Typography variant="h6">Product Breakdown</Typography>
        </Button>
        <Collapse in={isProductBreakdownExpanded}>
          <Grid container spacing={2}>
            {selectedMarketMoveRun.marketMoveRunProductConfigs?.map(
              (product) => (
                <ProductCard
                  key={product.productId}
                  productId={product.productId}
                  recapDetails={typedRecapDetails}
                  selectedMarketMove={selectedMarketMoveRun}
                />
              )
            )}
          </Grid>
        </Collapse>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Review Table
        </Typography>
        <ProductTable />
      </Box>
    </Paper>
  );
});

export default Recap;
