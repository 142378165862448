import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { MarketMoveRunStatus } from "../api/marketMoveRunApi";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema
} from "../schema/schemas";

export interface MMRunSaveRequest {
  counter: number;
  marketMoveId?: string;
  runStatus: RunStatus;
}

export interface MMMetaDataSaveRequest {
  counter: number;
  runStatus: RunStatus;
  triggerMMRunSave?: boolean;
}

export interface PreviewDetailsFetchRequest {
  counter: number;
  shouldFetch: boolean;
}

interface MarketMoveState {
  selectedMarketMoveId: string | null;
  selectedMarketMove: MarketMoveMetaDataSchema | null;
  disableEditing: boolean;
  hasRefreshedSinceLastChange: boolean;
  refreshPreviewTrigger: number;
  mmRunSaveRequest: MMRunSaveRequest;
  mmMetaDataSaveReq: MMMetaDataSaveRequest;
  isPreviewStale: boolean;
  shouldFetchPreview: boolean;
  marketMoveRunStatuses: MarketMoveRunStatus[];
  setMarketMoveRunStatuses: (statuses: MarketMoveRunStatus[]) => void;
  incrementMMRunSaveRequest: (
    runStatus: RunStatus,
    marketMoveId?: string,
    callback?: () => void
  ) => void;
  incrementMMMetaDataSaveReq: (
    runStatus: RunStatus,
    triggerMMRunSave?: boolean,
    callback?: () => void
  ) => void;
  setSelectedMarketMoveId: (id: string | null) => void;
  setSelectedMarketMove: (marketMove: MarketMoveMetaDataSchema | null) => void;
  setDisableEditing: (disableEditing: boolean) => void;
  reset: (callback?: () => void) => void;
  getDisplayMarketMoves: (
    goldenData: MarketMoveMetaDataSchema[],
    currentMarketMove: MarketMoveMetaDataSchema | null
  ) => MarketMoveMetaDataSchema[];
  setHasRefreshedSinceLastChange: (value: boolean) => void;
  resetHasRefreshedSinceLastChange: () => void;
  triggerPreviewRefresh: () => void;
  setPreviewStale: (isStale: boolean) => void;
  setShouldFetchPreview: (shouldFetch: boolean, callback?: () => void) => void;
  hasInitialLoadCompleted: boolean;
  setHasInitialLoadCompleted: (value: boolean) => void;
  previewDetailsFetchReq: PreviewDetailsFetchRequest;
  incrementPreviewDetailsFetchReq: (shouldFetch: boolean, callback?: () => void) => void;
}

const useMarketMoveStore = create<MarketMoveState>()(
  immer((set, get) => ({
    selectedMarketMoveId: null,
    selectedMarketMove: null,
    disableEditing: false,
    hasRefreshedSinceLastChange: true,
    refreshPreviewTrigger: 0,
    mmRunSaveRequest: { counter: 0, runStatus: RunStatus.DRAFT },
    isPreviewStale: false,
    shouldFetchPreview: false,
    marketMoveRunStatuses: [],
    mmMetaDataSaveReq: {
      counter: 0,
      runStatus: RunStatus.DRAFT,
      triggerMMRunSave: false,
    },
    setSelectedMarketMoveId: (id: string | null) =>
      set((state) => {
        state.selectedMarketMoveId = id;
      }),
    setSelectedMarketMove: (marketMove: MarketMoveMetaDataSchema | null) =>
      set((state) => {
        state.selectedMarketMove = marketMove;
      }),
    setDisableEditing: (disableEditing: boolean) =>
      set((state) => {
        state.disableEditing = disableEditing;
      }),
    getDisplayMarketMoves: (
      goldenData: MarketMoveMetaDataSchema[],
      currentMarketMove: MarketMoveMetaDataSchema | null
    ): MarketMoveMetaDataSchema[] => {
      console.debug("Golden data:", goldenData);
      console.debug("Current market move:", currentMarketMove);

      const allMoves = new Map<string, MarketMoveMetaDataSchema>();

      goldenData.forEach((move) => allMoves.set(move.marketMoveId, move));

      if (!currentMarketMove || !currentMarketMove.marketMoveId) {
        return Array.from(allMoves.values());
      }

      if (
        currentMarketMove.marketMoveId &&
        !allMoves.has(currentMarketMove.marketMoveId)
      ) {
        allMoves.set(currentMarketMove.marketMoveId, currentMarketMove);
      } else {
        allMoves.set(currentMarketMove.marketMoveId, {
          ...allMoves.get(currentMarketMove.marketMoveId),
          ...currentMarketMove,
        });
      }

      const result = Array.from(allMoves.values());
      console.debug("Final result:", result);
      return result;
    },
    reset: (callback?: () => void) =>
      set((state) => {
        state.selectedMarketMoveId = null;
        state.selectedMarketMove = null;
        state.disableEditing = false;
        state.hasRefreshedSinceLastChange = true;
        state.refreshPreviewTrigger = 0;
        state.mmRunSaveRequest = { counter: 0, runStatus: RunStatus.DRAFT };
        state.isPreviewStale = false;
        state.shouldFetchPreview = false;
        state.marketMoveRunStatuses = [];
        state.mmMetaDataSaveReq = {
          counter: 0,
          runStatus: RunStatus.DRAFT,
          triggerMMRunSave: false,
        };
        state.hasInitialLoadCompleted = false;
        if (callback) {
          setTimeout(callback, 0);
        }
      }),
    setHasRefreshedSinceLastChange: (value: boolean) =>
      set((state) => {
        state.hasRefreshedSinceLastChange = value;
      }),
    resetHasRefreshedSinceLastChange: () =>
      set((state) => {
        state.hasRefreshedSinceLastChange = false;
      }),
    triggerPreviewRefresh: () =>
      set((state) => {
        state.refreshPreviewTrigger += 1;
        state.isPreviewStale = false;
      }),
    incrementMMRunSaveRequest: (
      runStatus: RunStatus,
      marketMoveId?: string,
      callback?: () => void
    ) =>
      set((state) => {
        state.mmRunSaveRequest = {
          counter: state.mmRunSaveRequest.counter + 1,
          runStatus,
          marketMoveId,
        };
        if (callback) {
          setTimeout(callback, 0);
        }
      }),
    incrementMMMetaDataSaveReq: (
      runStatus: RunStatus,
      triggerMMRunSave = false,
      callback?: () => void
    ) =>
      set((state) => {
        state.mmMetaDataSaveReq = {
          counter: state.mmMetaDataSaveReq.counter + 1,
          runStatus,
          triggerMMRunSave,
        };
        if (callback) {
          setTimeout(callback, 0);
        }
      }),
    setPreviewStale: (isStale: boolean) =>
      set((state) => {
        state.isPreviewStale = isStale;
      }),
    setShouldFetchPreview: (shouldFetch: boolean, callback?: () => void) =>
      set((state) => {
        state.shouldFetchPreview = shouldFetch;
        if (callback) {
          setTimeout(callback, 0);
        }
      }),
    setMarketMoveRunStatuses: (statuses: MarketMoveRunStatus[]) =>
      set((state) => {
        state.marketMoveRunStatuses = statuses;
      }),
    hasInitialLoadCompleted: false,
    setHasInitialLoadCompleted: (value: boolean) =>
      set((state) => {
        state.hasInitialLoadCompleted = value;
      }),
    previewDetailsFetchReq: { counter: 0, shouldFetch: false },
    incrementPreviewDetailsFetchReq: (shouldFetch: boolean, callback?: () => void) =>
      set((state) => {
        state.previewDetailsFetchReq = {
          counter: state.previewDetailsFetchReq.counter + 1,
          shouldFetch,
        };
        if (callback) {
          setTimeout(callback, 0);
        }
      }),
  }))
);

export default useMarketMoveStore;
