import {
  AccessTime,
  CalendarToday,
  ExpandLess,
  ExpandMore,
  Store,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useMemo, useState } from "react";
import {
  MarketMoveRunDetailSchema,
  MarketMoveRunSchema,
} from "../../../schema/schemas";
import { calculateDuration, formatLocalDate } from "../../../utils/textUtils";
import ProductTable from "../../previewTable/PreviewTable";
import ProductCard from "../Recap/ProductCard";
import PreviewTable from "../../previewTable/PreviewTable";
import { useGetSingleMMRun } from "../../../hooks/marketMoveRunApiHooks";

const MarketMoveCard: React.FC<{
  selectedMarketMove: MarketMoveRunSchema;
  runDetails: MarketMoveRunDetailSchema[];
}> = ({ selectedMarketMove: selectedMarketMoveRun, runDetails }) => {
  const { modifiedStoresCount, storesLeftOut } = useMemo(() => {
    const allStores = new Set(runDetails.map((detail) => detail.storeId));
    const modifiedStores = new Set(
      runDetails
        .filter((detail) => detail.includeFlag)
        .map((detail) => detail.storeId)
    );

    return {
      modifiedStoresCount: modifiedStores.size,
      totalStoresCount: allStores.size,
      storesLeftOut: allStores.size - modifiedStores.size,
    };
  }, [runDetails]);

  return (
    <Card variant="outlined">
      <CardContent sx={{ "& > *": { mb: 3 } }}>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
          Market Move: {selectedMarketMoveRun.marketMoveRun?.marketMoveName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarToday sx={{ mr: 2, color: "text.secondary" }} />
            <Box>
              <Typography variant="body2">Start Date:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {selectedMarketMoveRun.marketMoveRun?.startDate
                  ? formatLocalDate(
                      selectedMarketMoveRun.marketMoveRun.startDate
                    )
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarToday sx={{ mr: 2, color: "text.secondary" }} />
            <Box>
              <Typography variant="body2">End Date:</Typography>
              <Typography variant="body1" fontWeight="bold">
                {selectedMarketMoveRun.marketMoveRun?.endDate
                  ? formatLocalDate(selectedMarketMoveRun.marketMoveRun.endDate)
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "48px",
          }}
        >
          <AccessTime sx={{ mr: 2, color: "text.secondary" }} />
          <Typography variant="body1">
            Duration:{" "}
            <strong>
              {selectedMarketMoveRun.marketMoveRun?.startDate &&
              selectedMarketMoveRun.marketMoveRun?.endDate
                ? calculateDuration(
                    selectedMarketMoveRun.marketMoveRun.startDate,
                    selectedMarketMoveRun.marketMoveRun.endDate
                  )
                : "N/A"}
            </strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
            bgcolor: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Store sx={{ mr: 2, color: "text.secondary" }} />
            <Typography variant="body1">
              Stores affected: <strong>{modifiedStoresCount}</strong>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Store sx={{ mr: 2, color: "text.secondary" }} />
            <Typography variant="body1">
              Stores unaffected: <strong>{storesLeftOut}</strong>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const PastMarketMove: React.FC<{selectedPastMarketMoveRunId: string}> = React.memo(
  ({ selectedPastMarketMoveRunId }) => {
    const [isProductBreakdownExpanded, setIsProductBreakdownExpanded] =
      useState(true);

    // const {
    //   marketMoveRunMethods: { control },
    // } = useFormStore();

    const {
      data: selectedMarketMoveRun,
    } = useGetSingleMMRun(selectedPastMarketMoveRunId);

    useEffect(() => {
      console.debug("selectedMarketMoveRun", selectedMarketMoveRun);
    }, [selectedMarketMoveRun]);

    if (!selectedMarketMoveRun) {
      return (
        <Box>
          <Typography>No Market Move selected.</Typography>
        </Box>
      );
    }

    const typedRunDetails: MarketMoveRunDetailSchema[] =
      selectedMarketMoveRun.marketMoveRunDetails || [];

    const toggleProductBreakdown = () => {
      setIsProductBreakdownExpanded(!isProductBreakdownExpanded);
    };

    return (
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <MarketMoveCard
          selectedMarketMove={selectedMarketMoveRun}
          runDetails={typedRunDetails}
        />
        <Box sx={{ mt: 3 }}>
          <Button
            onClick={toggleProductBreakdown}
            startIcon={
              isProductBreakdownExpanded ? <ExpandLess /> : <ExpandMore />
            }
            sx={{ mb: 1 }}
          >
            <Typography variant="h6">Product Breakdown</Typography>
          </Button>
          <Collapse in={isProductBreakdownExpanded}>
            <Grid container spacing={2}>
              {selectedMarketMoveRun.marketMoveRunProductConfigs?.map(
                (product) => (
                  <ProductCard
                    key={product.productId}
                    productId={product.productId}
                    recapDetails={typedRunDetails}
                    selectedMarketMove={selectedMarketMoveRun}
                  />
                )
              )}
            </Grid>
          </Collapse>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Planned Market Move
          </Typography>
          <PreviewTable editable={false} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Actual Market Move
          </Typography>
          <ProductTable />
        </Box>
      </Paper>
    );
  }
);

export default PastMarketMove;
