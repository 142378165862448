import { SelectChangeEvent } from "@mui/material";
import { MRT_ColumnOrderState, MRT_ColumnSizingState, MRT_RowSelectionState } from "material-react-table";
import { useCallback, useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { PriceMode } from "../interfaces/enums";
import { useFormActions } from "../store/formStore";

export const useTableConfig = () => {
  const [sortMode, setSortMode] = useState<PriceMode>(PriceMode.ABSOLUTE);
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    "previewTable_columnSizing",
    {}
  );
  const [rowSelection, setRowSelection] = useLocalStorage<MRT_RowSelectionState>(
    "previewTable_rowSelection",
    {}
  );
  const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>(
    "previewTable_columnOrder",
    []
  );
  const [showLinkedProducts, setShowLinkedProducts] = useState(false);

  const { updateStoreProductConfig, getStoreProductConfig } = useFormActions();

  const storeProductConfigsManager = {
    updateConfig: updateStoreProductConfig,
    getConfig: getStoreProductConfig
  };

  const handleSortModeChange = useCallback(
    (event: SelectChangeEvent<PriceMode>) => {
      setSortMode(event.target.value as PriceMode);
    },
    []
  );

  const updateColumnOrder = useCallback((newOrder: MRT_ColumnOrderState) => {
    // const baseColumns = ['action', 'storeId', 'displayName'];
    // const defaultProductColumns = ['001', '019'];
    // const exclusionReasonColumn = 'exclusionReason';

    // let orderedColumns: string[];

    // if (Array.isArray(newOrder)) {
    //   orderedColumns = [
    //     ...baseColumns,
    //     ...defaultProductColumns,
    //     ...newOrder.filter(col => 
    //       typeof col === 'string' && 
    //       !baseColumns.includes(col) && 
    //       !defaultProductColumns.includes(col) && 
    //       col !== exclusionReasonColumn
    //     ),
    //     exclusionReasonColumn
    //   ];
    // } else {
    //   // If newOrder is not an array, maintain the current order or use a default order
    //   orderedColumns = columnOrder.length > 0 
    //     ? columnOrder 
    //     : [...baseColumns, ...defaultProductColumns, exclusionReasonColumn];
    // }

    // setColumnOrder(orderedColumns);
  }, [columnOrder, setColumnOrder]);

  // Initialize column order if it's empty
  useEffect(() => {
    if (columnOrder.length === 0) {
      updateColumnOrder(['action', 'storeId', 'displayName', '001', '019', 'exclusionReason']);
    }
  }, [columnOrder, updateColumnOrder]);

  return {
    sortMode,
    handleSortModeChange,
    storeProductConfigsManager,
    showLinkedProducts,
    setShowLinkedProducts,
    tableState: {
      columnSizing,
      rowSelection,
      columnOrder,
      setRowSelection,
      setColumnOrder: updateColumnOrder,
      setColumnSizing,
    },
  };
};