import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, SelectChangeEvent } from "@mui/material";
import {
  MRT_ActionMenuItem,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useMemo } from "react";
import { PriceMode } from "../../interfaces/enums";
import { MarketMoveRunDetailSchema } from "../../schema/schemas";
import {
  useFormActions,
  usePreviewDetailsMethods,
} from "../../store/formStore";
import useMarketMoveStore from "../../store/marketMoveStore";
import { tableTheme } from "../../theme/theme";
import LinkedProductsToggle from "./LinkedProductsToggle";
import { generatePreviewTableColumnDefs } from "./previewTableColumnUtils";
import SortModeSelect from "./SortModeSelect";
import { usePreviewData } from "../../hooks/usePreviewData";
import { useTableConfig } from "../../hooks/usePreviewTableConfig";

const PreviewTable: React.FC<{ editable?: boolean }> = React.memo(
  ({ editable = false }) => {
    const { disableEditing } = useMarketMoveStore();
    const { getStoreProductConfig } = useFormActions();
    const {
      formState: { isDirty: isPreviewDetailsDirty },
    } = usePreviewDetailsMethods();

    const { recapData, tableData, selectedMarketMove, callState } =
      usePreviewData();
    const {
      sortMode,
      handleSortModeChange,
      storeProductConfigsManager,
      showLinkedProducts,
      setShowLinkedProducts,
      tableState,
    } = useTableConfig();
    const canEdit = editable && !disableEditing;

    const tableColumns = useMemo(
      () =>
        generatePreviewTableColumnDefs(
          recapData,
          sortMode,
          storeProductConfigsManager,
          showLinkedProducts,
          !canEdit,
          tableState.columnOrder
        ),
      [recapData, sortMode, showLinkedProducts, canEdit, tableState.columnOrder]
    );

    const getMissingDataMessage = () => {
      if (!selectedMarketMove?.storeList?.length) {
        return "Please select at least one store to view the product table.";
      }
      if (!selectedMarketMove?.productConfigList?.length) {
        return "Please configure at least one product to view the table.";
      }
      if (!recapData) {
        return "Loading preview details...";
      }
      if (callState.isError) {
        return `Error fetching preview details: ${callState.error}`;
      }
      if (isPreviewDetailsDirty) {
        return "Refresh the preview table to see the latest data.";
      }
      return "No data available for the selected stores and products.";
    };

    const renderTopToolbarCustomActions = () => (
      <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        <SortModeSelect
          sortMode={sortMode}
          handleSortModeChange={() =>
            handleSortModeChange({
              target: {
                value:
                  sortMode === PriceMode.INCREMENTAL
                    ? PriceMode.ABSOLUTE
                    : PriceMode.INCREMENTAL,
              },
            } as SelectChangeEvent<PriceMode>)
          }
        />
        <LinkedProductsToggle
          showLinkedProducts={showLinkedProducts}
          setShowLinkedProducts={setShowLinkedProducts}
        />
      </Box>
    );

    const renderEmptyRowsFallback = () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "16px",
          color: "text.secondary",
        }}
      >
        {getMissingDataMessage()}
      </Box>
    );

    const handleToggleExclusion = (
      closeMenu: () => void,
      details: MarketMoveRunDetailSchema,
      currentFlag: boolean
    ) => {
      storeProductConfigsManager.updateConfig(
        details.storeId,
        details.productId,
        {
          includeFlag: !currentFlag,
        }
      );
      closeMenu();
    };

    const handleRemovePriceOverride = (closeMenu: () => void, cell: any) => {
      const details: MarketMoveRunDetailSchema = cell.getValue();

      storeProductConfigsManager.updateConfig(
        details.storeId,
        details.productId,
        {
          manualPriceOverrideFlag: false,
          manualPrice: 0,
        }
      );
      closeMenu();
    };

    const renderCellActionMenuItems = ({ closeMenu, cell }: any) => {
      const currentConfig = getStoreProductConfig(
        cell.getValue()?.["storeId"],
        cell.getValue()?.["productId"]
      );
      const cellDetails: MarketMoveRunDetailSchema = cell.getValue();
      const currentFlag = currentConfig
        ? currentConfig.includeFlag
        : cellDetails.includeFlag && !cellDetails.excludePriceFlag;
      return [
        <MRT_ActionMenuItem
          key="toggle-exclude"
          label={currentFlag ? "Exclude this product" : "Include this product"}
          icon={currentFlag ? <RemoveCircleOutlineIcon /> : <CheckCircleIcon />}
          onClick={() =>
            handleToggleExclusion(closeMenu, cellDetails, currentFlag)
          }
          table={table}
        />,
        <MRT_ActionMenuItem
          key="remove-price-override"
          label="Remove Price Override"
          icon={<MoneyOffIcon />}
          onClick={() => handleRemovePriceOverride(closeMenu, cell)}
          table={table}
          disabled={
            !canEdit ||
            (!cellDetails?.manualPriceOverrideFlag &&
              !currentConfig?.manualPriceOverrideFlag)
          }
        />,
      ];
    };

    const table = useMaterialReactTable({
      columns: tableColumns,
      data: tableData,
      enableGrouping: true,
      enableRowVirtualization: true,
      enablePagination: false,
      enableColumnDragging: true,
      enableColumnResizing: true,
      enableStickyHeader: true,
      enableEditing: canEdit,
      enableRowSelection: false,
      positionToolbarAlertBanner: "none",
      muiTableHeadCellProps: { align: "center", sx: { whiteSpace: "nowrap" } },
      muiTableBodyCellProps: { align: "center" },
      getRowId: (originalRow) => originalRow.storeId,
      editDisplayMode: "cell",
      layoutMode: "grid-no-grow",
      enableCellActions: (cell) =>
        canEdit && Boolean(cell.getValue()?.["productId"]),
      onColumnSizingChange: tableState.setColumnSizing,
      onColumnOrderChange: tableState.setColumnOrder,
      initialState: {
        expanded: true,
        density: "compact",
        sorting: [
          {
            id: "exclusionReason",
            desc: true,
          },
        ],
      },
      state: {
        columnSizing: tableState.columnSizing,
        columnOrder: tableState.columnOrder,
        isLoading: callState.isLoading,
      },
      defaultColumn: {
        size: 50,
        minSize: 10,
      },
      ...tableTheme,
      renderTopToolbarCustomActions,
      renderEmptyRowsFallback,
      renderCellActionMenuItems,
    });

    return <MaterialReactTable table={table} />;
  }
);

export default PreviewTable;
