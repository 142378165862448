import { MRT_RowSelectionState } from "material-react-table";
import { create } from "zustand";
import {
  IStore,
  ProductInfo,
  StoreElasticData
} from "../interfaces/IStore";
import { getUniqueProducts } from "./actions";

export interface GeneralStoreState {
  storeData: StoreElasticData[];
  rowSelection: MRT_RowSelectionState;
  uniqueProducts: ProductInfo[];
  elasticData: IStore[];
  setStoreData: (data: any) => void;
  setRowSelection: (data: MRT_RowSelectionState) => void;
  showPreview: boolean;
  togglePreview: () => void;
  setShowPreview: (show: boolean) => void;
  showPastMarketMoves: boolean;
  setShowPastMarketMoves: (show: boolean) => void;
  showRecap: boolean;
  toggleRecap: () => void;
  listViewSelectedStores: string[];
  setListViewSelectedStores: (stores: string[]) => void;
  setElasticData: (data: IStore[]) => void;
  reset: () => void; // Add this line
}

const useGeneralStore = create<GeneralStoreState>((set) => ({
  storeData: [],
  rowSelection: {},
  uniqueProducts: getUniqueProducts(),
  elasticData: [],
  setStoreData: (data: StoreElasticData[]) => set({ storeData: data }),
  setRowSelection: (data: MRT_RowSelectionState) => set({ rowSelection: data }),
  showPreview: false,
  togglePreview: () => set((state) => ({ showPreview: !state.showPreview })),
  setShowPreview: (show: boolean) => set({ showPreview: show }),
  showPastMarketMoves: false,
  setShowPastMarketMoves: (show: boolean) => set({ showPastMarketMoves: show }),
  showRecap: false,
  toggleRecap: () => set((state) => ({ showRecap: !state.showRecap })),
  listViewSelectedStores: [],
  setListViewSelectedStores: (stores: string[]) =>
    set({ listViewSelectedStores: stores }),
  setElasticData: (data: IStore[]) => set({ elasticData: data }),
  reset: () => set({
    storeData: [],
    rowSelection: {},
    uniqueProducts: getUniqueProducts(),
    elasticData: [],
    showPreview: false,
    showRecap: false,
    showPastMarketMoves: false,
    listViewSelectedStores: [],
  }),
}));

export default useGeneralStore;