import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo"; // Changed import from DeleteIcon to UndoIcon
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  useCreateMarketMove,
  useGetMMList,
  useUpdateMarketMove,
} from "../../../hooks/marketMoveMetaDataApiHooks";
import { RunStatus } from "../../../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveRunSchema,
} from "../../../schema/schemas";
import { useFormStore, useMarketMoveMetaDataMethods } from "../../../store/formStore";
import useMarketMoveStore from "../../../store/marketMoveStore";
import { generateDefaultMarketMove } from "../../../utils/marketMoveUtils";
import ZonedDateTimePicker from "../../shared/ZonedDateTimePicker";
import MarketMoveNameField from "./MarketMoveNameField";
import StoreListTable from "./storeList/StoreListTable";

const MarketMoveSettings: React.FC = () => {
  const { isError, isLoading, error } = useGetMMList();
  const {
    setSelectedMarketMoveId,
    disableEditing,
    setShouldFetchPreview,
    setDisableEditing,
    incrementPreviewDetailsFetchReq,
    incrementMMMetaDataSaveReq  } = useMarketMoveStore();
  const {
    marketMoveMetaDataMethods,
    marketMoveRunMethods: { control: marketMoveRunControl },
    previewDetailsMethods: { formState: previewDetailsFormState },
  } = useFormStore();
  const createMarketMoveMetaData = useCreateMarketMove();
  const updateMarketMoveMetaData = useUpdateMarketMove();
  const {
    formState: marketMoveMetaDataFormState,
    trigger,
    control,
    reset: resetForm,
  } = marketMoveMetaDataMethods;

  const metaDataChangedFields = Object.keys(
    marketMoveMetaDataFormState.dirtyFields
  ).map(capitalize);
  const previewDetailsChangedFields = Object.keys(
    previewDetailsFormState.dirtyFields
  ).map(capitalize);

  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });
  const currentMarketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);

  // Define separate conditions
  const isMetaDataDirty = marketMoveMetaDataFormState.isDirty;
  const isPreviewDetailsDirty = previewDetailsFormState.isDirty;
  const hasRecapDetailsWithoutId =
    !currentMarketMove.marketMoveRunId &&
    currentMarketMoveRun.marketMoveRunRecapDetails.length > 0;

  // Combine conditions into isFormDirty
  const isFormDirty =
    isMetaDataDirty || isPreviewDetailsDirty || hasRecapDetailsWithoutId;


  const handleSave = () => {
      incrementPreviewDetailsFetchReq(true, () => {
        trigger().then((isValid) => {
          if (isValid) {
            incrementMMMetaDataSaveReq(RunStatus.DRAFT, true);
          } else {
          }
        });
      });
  };

  const { trigger: useFormTrigger } = useMarketMoveMetaDataMethods(); // Use useFormContext to access trigger

  // Watch the endDate field
  const endDate = useWatch({ control, name: "endDate" });

  useEffect(() => {
    if (isError) setOpenSnackbar(true);
  }, [isError]);

  useEffect(() => {
    setDisableEditing(currentMarketMoveRun?.marketMoveRun?.runStatus === RunStatus.INPROGRESS);
  }, [currentMarketMoveRun?.marketMoveRun?.runStatus]);

  useEffect(() => {
    handleErrorIconVisibility();
  }, [createMarketMoveMetaData.isError, updateMarketMoveMetaData.isError]);

  useEffect(() => {
    if (endDate) {
      useFormTrigger("startDate"); // Trigger validation on startDate when endDate changes
    }
  }, [endDate, useFormTrigger]);

  const handleErrorIconVisibility = () => {
    if (createMarketMoveMetaData.isError || updateMarketMoveMetaData.isError) {
      setShowErrorIcon(true);
      const timer = setTimeout(() => setShowErrorIcon(false), 3000);
      return () => clearTimeout(timer);
    }
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleCancel = () => {
    if (isFormDirty) {
      setOpenDialog(true);
    } else {
      resetForm(null);
    }
  };

  const handleDialogClose = (event: object, reason: string) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };

  const handleDiscard = () => {
    resetForm(generateDefaultMarketMove());
    setSelectedMarketMoveId(null);
    setOpenDialog(false);
  };

  const getDirtyFieldsTooltip = () => {
    const errors = marketMoveMetaDataFormState.errors;

    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.entries(errors)
        .map(([field, error]) => `${field}: ${error?.message}`)
        .join(", ");
      return `Errors: ${errorMessages}`;
    }

    let changes = [];

    if (hasRecapDetailsWithoutId) {
      changes.push("Recap details available that haven't been saved");
    }

    if (isFormDirty) {
      const metaDataChanges =
        metaDataChangedFields.length > 0
          ? `MetaData: ${metaDataChangedFields.join(", ")}`
          : null;

      const previewDetailsChanges =
        previewDetailsChangedFields.length > 0
          ? `PreviewDetails: ${previewDetailsChangedFields.join(", ")}`
          : null;

      changes.push(metaDataChanges, previewDetailsChanges);
    } else {
      changes.push("No changes to save");
    }

    return `Changes - ${changes.filter(Boolean).join(" | ")}`;
  };

  const createDateField = (
    label: string,
    valueKey: 'startDate' | 'endDate'
  ) => (
    <ZonedDateTimePicker name={valueKey} />
  );

  const renderHeader = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <Tooltip title={getDirtyFieldsTooltip()}>
        <span>
          <IconButton
            aria-label="Save changes"
            sx={{ color: isFormDirty ? "green" : "gray" }}
            disabled={
              !isFormDirty ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading ||
              disableEditing ||
              !marketMoveMetaDataFormState.isValid
            }
            onClick={handleSave}
          >
            {createMarketMoveMetaData.isLoading ||
            updateMarketMoveMetaData.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : showErrorIcon ? (
              <CloseIcon sx={{ color: "red" }} />
            ) : (
              <SaveIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Typography
        variant="subtitle1"
        style={{ flexGrow: 1, textAlign: "center" }}
      >
        Market Move Settings
      </Typography>
      <Tooltip title="Undo changes">
        <span>
          <IconButton
            aria-label="Undo changes"
            sx={{ color: isFormDirty ? "red" : "gray" }}
            onClick={handleCancel}
            disabled={
              !isFormDirty ||
              isLoading ||
              createMarketMoveMetaData.isLoading ||
              updateMarketMoveMetaData.isLoading
            }
          >
            <UndoIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );

  const renderContent = () => (
    <>
      {/* <ErrorDisplay errors={marketMoveMetaDataFormState.errors} /> */}

      <Grid container>
        <Grid item xs={12} mb={2}>
          <MarketMoveNameField />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          {createDateField("Start Date (CST)", "startDate")}
        </Grid>
        <Grid item xs={3}>
          {createDateField("End Date (CST)", "endDate")}
        </Grid>
        <Grid item xs={6}>
          <StoreListTable />
        </Grid>
      </Grid>
    </>
  );

  const renderSkeleton = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={56} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} className="marketMoveSettings">
        <Box borderRadius={2}>
          {renderHeader()}
          {isLoading ? renderSkeleton() : renderContent()}
        </Box>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error?.message || "An error occurred while loading market moves."}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogContent>
          <DialogContentText>
            Do you want to discard the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            variant={"contained"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDiscard}
            color="error"
            variant={"contained"}
            autoFocus
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarketMoveSettings;
