import InfoIcon from "@mui/icons-material/Info";
import { Box, InputAdornment, TextField, Tooltip, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { MarketMoveTemplateStatus, RunStatus } from "../../../interfaces/enums";
import useMarketMoveStore from "../../../store/marketMoveStore";
import { MarketMoveMetaDataSchema } from "../../../schema/schemas";
import { useFormStore } from "../../../store/formStore";

const helpText = "State - DMA - Product - Price/Incremental Amount - Username";

const MarketMoveNameField: React.FC = () => {
  const {
    marketMoveMetaDataMethods: { control, setValue, formState: { errors } },
  } = useFormStore();

  const { disableEditing } = useMarketMoveStore();

  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });
  const [marketMoveName, setMarketMoveName] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue("marketMoveName", marketMoveName, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [marketMoveName, setValue]);

  useEffect(() => {
    if (currentMarketMove?.marketMoveId) {
      setMarketMoveName(currentMarketMove.marketMoveName);
    } else {
      setMarketMoveName("");
    }
  }, [currentMarketMove?.marketMoveId]);

  const handleInfoClick = () => {
    setMarketMoveName(helpText);
  };

  return (
    <Box>
      <TextField
        value={marketMoveName}
        onChange={(e) => setMarketMoveName(e.target.value)}
        fullWidth
        size="small"
        label="Market Move Name"
        error={!!errors.marketMoveName}
        helperText={errors.marketMoveName?.message}
        disabled={disableEditing}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={"Recommended: " + helpText}>
                <IconButton
                  onClick={handleInfoClick}
                  disabled={disableEditing}
                  size="small"
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default MarketMoveNameField;