import {
  // UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  createMarketMove,
  getSingleMMRun,
  // MarketMoveRunStatus,
  updateMarketMove,
  getMarketMoveRunsByRunStatus,
} from "../api/marketMoveRunApi";
import { MarketMoveRunSchema } from "../schema/schemas";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

// export const useGetMMRunList = (
//   marketMoveIds: string[]
// ): UseQueryResult<MarketMoveRunStatus[], Error> => {
//   return useQuery({
//     queryKey: ["marketMoveRuns", marketMoveIds],
//     queryFn: () => [],
//     enabled: marketMoveIds.length > 0,
//     retry: 1,
//   });
// };

export const useCreateMarketMoveRun = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveRunSchema) =>
      createMarketMove(marketMove),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["marketMoveRuns"] });
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      queryClient.invalidateQueries({
        queryKey: ["marketMove", variables.marketMoveRun.marketMoveId],
      });
    },
  });
};

export const useUpdateMarketMoveRun = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (marketMove: MarketMoveRunSchema) =>
      updateMarketMove(marketMove),
    onSuccess: (_, variables) => {
      console.debug(
        "marketMoveRun useUpdateMarketMoveRun onSuccess",
        variables
      );
      queryClient.invalidateQueries({ queryKey: ["marketMoves"] });
      queryClient.invalidateQueries({
        queryKey: ["marketMoveRun", variables.marketMoveRun.marketMoveRunId],
      });
      queryClient.invalidateQueries({
        queryKey: ["marketMove", variables.marketMoveRun.marketMoveId],
      });
    },
  });
};

export const useGetSingleMMRun = (mmId: string | null) => {
  return useQuery({
    queryKey: ["marketMoveRun", mmId],
    queryFn: () => getSingleMMRun(mmId),
    enabled: !!mmId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.debug("marketMoveRun useGetSingleMMRun onSuccess", data);
    },
    onError: (error: AxiosError) => {
      console.error("marketMoveRun useGetSingleMMRun onError", error);
      enqueueSnackbar(`Error fetching market move run: ${error.message}`, {
        variant: "error",
      });
    },
    retry: 1,
  });
};

export const useInfiniteScrollGetMarketMoveRunsByRunStatus = (
  statuses: string[],
  sortBy: string,
  sortDirection: string,
  searchValue: string
) => {
  return useInfiniteQuery({
    queryKey: [
      "InfiniteMarketMoveRunsByRunStatus",
      statuses,
      sortBy,
      sortDirection,
      searchValue,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getMarketMoveRunsByRunStatus(
        statuses,
        sortBy,
        sortDirection,
        pageParam,
        5,
        searchValue
      ),
    getNextPageParam: (lastPage, pages) => lastPage.nextPage,
  });
};
