import { CancelToken } from "axios";
import constants from "../constants/constants.json";
import { constructESHeader, formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
  urlConstants: { elasticSearch },
} = constants;

const getElasticSearchUrl = async () => {
  const hostname = window.location.hostname;
  return formServiceUrl(
    elasticSearch.name,
    true,
    hostname.includes("localhost") ? "dev" : undefined
  );
};

const apiClient = createApiClient(getElasticSearchUrl, false);

interface ElasticSearchResponse {
  hits: {
    hits: Array<{
      _source: any;
      _id: string;
    }>;
  };
}

const fetchFromElastic = async (
  query: any,
  cancelToken?: CancelToken
): Promise<ElasticSearchResponse> => {
  const headers = await constructESHeader();
  return apiClient.post(elasticSearch.geolocationSearch, query, {
    cancelToken,
    headers,
  });
};

const baseQuery = {
  query: {
    bool: {
      must: [{ term: { isCompetitor: false } }],
    },
  },
  sort: [{ _id: { order: "asc" } }],
  size: 10000,
  search_after: [],
};

export const fetchStoresFromElastic = (cancelToken?: CancelToken) =>
  fetchFromElastic(baseQuery, cancelToken).then((result) =>
    result.hits.hits.map((hit) => hit._source)
  );

export const fetchStoresWithSearchAfter = (
  searchAfterIndex: string,
  batchSize: number,
  cancelToken?: CancelToken
) =>
  fetchFromElastic(
    {
      ...baseQuery,
      size: batchSize,
      search_after: [searchAfterIndex],
    },
    cancelToken
  );

export const fetchAllStores = async () => {
  const batchSize = 10000;
  const response = await fetchStoresWithSearchAfter("", batchSize);
  return response.hits.hits.map((hit) => hit._source);
};
