import { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IStore } from "../../../../interfaces/IStore";
import {
  MarketMoveMetaDataSchema,
  MarketMoveStoreListSchema,
} from "../../../../schema/schemas";
import useGeneralStore from "../../../../store/generalStore";
import { formatISODate } from "../../../../utils/textUtils";
import { useFormStore } from "../../../../store/formStore";

enum MarketMovePresence {
  NEW = "NEW",
  EXISTING = "EXISTING",
  NONE = "NONE",
}

// Helper function to convert IStore to MarketMoveStoreListSchema
// This ensures consistency when creating new store list items
export const convertToMMStore = (
  store: IStore,
  includeFlag: boolean
): MarketMoveStoreListSchema => ({
  storeId: store.importcode,
  includeDate: includeFlag ? formatISODate() : "",
  includeFlag: includeFlag,
  flag: "NOUPDATE",
});

export const useStoreList = () => {
  const { listViewSelectedStores, elasticData } = useGeneralStore();
  const { marketMoveMetaDataMethods : {setValue, control}} = useFormStore()
  const selectedMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });

  const getSelectedMarketMovePresence = useMemo((): MarketMovePresence => {
    if (selectedMarketMove?.marketMoveId === "") return MarketMovePresence.NEW;
    if (selectedMarketMove?.marketMoveId === null)
      return MarketMovePresence.NONE;
    return MarketMovePresence.EXISTING;
  }, [selectedMarketMove?.marketMoveId]);

  const storeList = useMemo(() => {
    const selectedStoreList = selectedMarketMove?.storeList;
    if (selectedStoreList && selectedStoreList.length > 0) {
      return selectedStoreList;
    }
    const filteredStores = elasticData.filter((store) =>
      listViewSelectedStores.includes(store.importcode)
    );

    const mappedStores = filteredStores.map((store) =>
      convertToMMStore(store, true)
    );

    return mappedStores;
  }, [
    selectedMarketMove.marketMoveId,
    elasticData,
    listViewSelectedStores,
    selectedMarketMove.storeList,
  ]);

  const initialStoreListRef = useRef<MarketMoveStoreListSchema[]>();


  useEffect(() => {
    const presence = getSelectedMarketMovePresence;
    if (!elasticData || elasticData.length === 0) return;

    if (presence === MarketMovePresence.EXISTING && elasticData.length > 0) {
      const selectedStoreList = selectedMarketMove?.storeList || [];
      initialStoreListRef.current = selectedStoreList;
      if (selectedStoreList.length === 0) {
        setValue("storeList", storeList, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    } else if (presence === MarketMovePresence.NEW) {
      setValue("storeList", storeList, {
        shouldDirty: false,
        shouldValidate: true,
      });
    } else {
      initialStoreListRef.current = [];
    }
  }, [selectedMarketMove?.marketMoveId, elasticData]);

  const workingElasticData = useMemo(
    () =>
      elasticData
        .filter((store) =>
          storeList.some((s) => s.storeId === store.importcode)
        )
        .map((store) => ({
          ...store,
          includeFlag:
            storeList.find((s) => s.storeId === store.importcode)
              ?.includeFlag ?? false,
        })),
    [elasticData, storeList]
  );

  const determineFlag = (
    newIncluded: boolean,
    initialIncluded: boolean | undefined
  ): "UPDATE" | "NOUPDATE" =>
    newIncluded !== initialIncluded ? "UPDATE" : "NOUPDATE";

  const updateStoreItem = useCallback(
    (
      store: MarketMoveStoreListSchema,
      newIncluded: boolean
    ): MarketMoveStoreListSchema => {
      const initialStore = initialStoreListRef.current?.find(
        (s) => s.storeId === store.storeId
      );
      const flag = determineFlag(newIncluded, initialStore?.includeFlag);
      return {
        ...store,
        includeFlag: newIncluded,
        includeDate: newIncluded ? formatISODate() : store.includeDate,
        flag,
      };
    },
    [determineFlag]
  );

  const handleToggle = useCallback(
    (importcodes?: string | string[]) => {
      const codesToToggle = importcodes
        ? Array.isArray(importcodes)
          ? importcodes
          : [importcodes]
        : null;
      const allIncluded =
        codesToToggle === null && storeList.every((store) => store.includeFlag);

      const newStoreList = storeList.map((store) => {
        const shouldToggle = codesToToggle
          ? codesToToggle.includes(store.storeId)
          : true;
        const newIncluded = shouldToggle
          ? codesToToggle
            ? !store.includeFlag
            : !allIncluded
          : store.includeFlag;
        return updateStoreItem(store, newIncluded);
      });

      setValue("storeList", newStoreList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [storeList, setValue, updateStoreItem]
  );

  const resetStoreList = useCallback(() => {
    if (initialStoreListRef.current) {
      setValue("storeList", initialStoreListRef.current, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      const resetList = elasticData
        .filter((store) => listViewSelectedStores.includes(store.importcode))
        .map((store) => convertToMMStore(store, true));
      setValue("storeList", resetList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [elasticData, listViewSelectedStores, setValue]);

  return {
    storeList,
    workingElasticData,
    handleToggle,
    setValue,
    getSelectedMarketMovePresence,
    resetStoreList,
  };
};
