import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import {
  useCreateMarketMoveRun,
  useGetSingleMMRun,
  useUpdateMarketMoveRun,
} from "../hooks/marketMoveRunApiHooks";
import { RunStatus } from "../interfaces/enums";
import {
  MarketMoveMetaDataSchema,
  MarketMoveProductConfigSchema,
  MarketMoveRunSchema,
} from "../schema/schemas";
import {
  useMarketMoveMetaDataMethods,
  useMarketMoveRunMethods,
  usePreviewDetailsMethods,
} from "../store/formStore";
import useGeneralStore from "../store/generalStore";
import useMarketMoveStore, { MMRunSaveRequest } from "../store/marketMoveStore";
import { generateDefaultMarketMoveRun } from "../utils/marketMoveUtils";

export const useMarketMoveRun = () => {
  const { setShowPreview } = useGeneralStore();
  const { mmRunSaveRequest } = useMarketMoveStore();
  const createMarketMoveRun = useCreateMarketMoveRun();
  const updateMarketMoveRun = useUpdateMarketMoveRun();

  const {
    control: marketMoveRunControl,
    reset: marketMoveRunReset,
    setValue: marketMoveRunSetValue,
  } = useMarketMoveRunMethods();
  const marketMoveRun = useWatch<MarketMoveRunSchema>({
    control: marketMoveRunControl,
  });

  const { control: marketMoveMetaDataControl, trigger } =
    useMarketMoveMetaDataMethods();
  const marketMoveMetaData = useWatch<MarketMoveMetaDataSchema>({
    control: marketMoveMetaDataControl,
  });

  const { reset: previewReset } = usePreviewDetailsMethods();

  const { data, isFetching, error } = useGetSingleMMRun(
    marketMoveMetaData?.marketMoveRunId
  );

  // 4. Handle data updates
  useEffect(() => {
    if (data) {
      marketMoveRunReset(data);
    } else {
      marketMoveRunReset(generateDefaultMarketMoveRun());
    }
  }, [data]);

  useEffect(() => {
    if (marketMoveRun?.marketMoveRun) {
      previewReset({
        marketMoveId: marketMoveRun?.marketMoveRun?.marketMoveId,
        marketMoveRunId: marketMoveRun?.marketMoveRun?.marketMoveRunId,
        marketMoveName: marketMoveRun?.marketMoveRun?.marketMoveName,
        marketMoveStores: marketMoveMetaData?.storeList,
        marketMoveProductConfigs: mergeProductConfigs(
          marketMoveRun.marketMoveRunProductConfigs,
          marketMoveMetaData?.productConfigList
        ),
        storeProductConfig: [],
      });
    } else {
      previewReset({
        marketMoveId: marketMoveMetaData?.marketMoveId,
        marketMoveRunId: marketMoveMetaData?.marketMoveRunId,
        marketMoveName: marketMoveMetaData?.marketMoveName,
        marketMoveStores: marketMoveMetaData?.storeList,
        marketMoveProductConfigs: marketMoveMetaData?.productConfigList,
        storeProductConfig: [],
      });
      marketMoveRunSetValue("marketMoveRunRecapDetails", []);
    }
    if (marketMoveRun?.marketMoveRunRecapDetails?.length > 0) {
      setShowPreview(true);
    }
  }, [marketMoveRun?.marketMoveRun?.marketMoveId]);

  useEffect(() => {
    const updatedMarketMoveRunMaster = {
      ...marketMoveRun?.marketMoveRun,
      ...marketMoveMetaData,
    };
    updateMarketMoveRunFields(updatedMarketMoveRunMaster);
  }, [marketMoveMetaData]);

  useEffect(() => {
    if (mmRunSaveRequest.counter > 0) {
      trigger().then((isValid) => {
        if (isValid) {
          handleSave(mmRunSaveRequest);
        } else {
          enqueueSnackbar("Please correct the highlighted errors before saving.", {
            variant: "error",
          });
        }
      });
    }
  }, [mmRunSaveRequest.counter]);


  // 5. Helper functions
  const handleSave = (request: MMRunSaveRequest) => {
    if (!marketMoveRun?.marketMoveRun) {
      return;
    }

    if (!marketMoveRun?.marketMoveRun?.marketMoveRunId) {
      const cleanedPayload = cleanMarketMoveRunPayload(marketMoveRun, "CREATE");
      cleanedPayload.marketMoveRun.marketMoveId =
        mmRunSaveRequest.marketMoveId || marketMoveMetaData.marketMoveId;

      createMarketMoveRun.mutate(cleanedPayload, {
        onSuccess: (mm) => {
          marketMoveRunReset(mm);
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(`Error creating market move run: ${error.message}`, {
            variant: "error",
          });
        },
      });
    } else {
      const cleanedPayload = cleanMarketMoveRunPayload(marketMoveRun, "UPDATE");
      cleanedPayload.marketMoveRun.runStatus = mmRunSaveRequest.runStatus;

      updateMarketMoveRun.mutate(cleanedPayload, {
        onSuccess: (mm) => {
          console.log("Market Move Run updated", mm);
          if (mmRunSaveRequest.runStatus === RunStatus.SIGNEDOFF) {
            enqueueSnackbar("Market Move Run submitted", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Market Move Run updated", { variant: "success" });
          }
          marketMoveRunReset(mm);
        },
        onError: (error: AxiosError) => {
          console.error("Error updating Market Move Run", error);
          enqueueSnackbar(`Error updating market move run: ${error.message}`, {
            variant: "error",
          });
        },
      });
    }
  };

  const cleanMarketMoveRunPayload = (
    payload: MarketMoveRunSchema,
    saveMode: "CREATE" | "UPDATE"
  ) => {
    if (!payload || !payload.marketMoveRun) {
      return payload;
    }

    const cleanedPayload = { ...payload };
    cleanedPayload.marketMoveRun.runStatus = RunStatus.DRAFT;

    if (saveMode === "CREATE") {
      cleanedPayload.marketMoveRunProductConfigs =
        cleanedPayload.marketMoveRunProductConfigs.map((config) => {
          const { mmRunProdConfigId, ...rest } = config;
          return rest;
        });

      cleanedPayload.marketMoveRunRecapDetails =
      cleanedPayload.marketMoveRunRecapDetails =
        cleanedPayload.marketMoveRunRecapDetails.map((detail) => {
          const { mmRunRecapId, ...rest } = detail;
          return rest;
        });
    }

    if (cleanedPayload.marketMoveRun.storeList) {
      delete cleanedPayload.marketMoveRun.storeList;
    }
    if (cleanedPayload.marketMoveRun.productConfigList) {
      delete cleanedPayload.marketMoveRun.productConfigList;
    }
    return cleanedPayload;
  };

  function mergeProductConfigs(
    existingConfigs: MarketMoveProductConfigSchema[],
    newConfigs: MarketMoveProductConfigSchema[]
  ): MarketMoveProductConfigSchema[] {
    const existingConfigMap = new Map<string, MarketMoveProductConfigSchema>();

    // Create a map of existing configs
    existingConfigs.forEach((config) => {
      existingConfigMap.set(config.productId, config);
    });

    // Merge new configs with existing ones, only including configs present in the new set
    return newConfigs.map((newConfig) => {
      const existingConfig = existingConfigMap.get(newConfig.productId);
      return {
        ...newConfig,
        mmRunProdConfigId:
          existingConfig?.mmRunProdConfigId ?? newConfig.mmRunProdConfigId,
        marketMoveRunId:
          existingConfig?.marketMoveRunId ?? newConfig.marketMoveRunId,
        productConfigId:
          existingConfig?.productConfigId ?? newConfig.productConfigId,
      };
    });
  }

  const updateMarketMoveRunFields = (
    marketMoveMetaData: MarketMoveMetaDataSchema | undefined
  ): void => {
    const mergedProductConfigs = mergeProductConfigs(
      data?.marketMoveRunProductConfigs || [], // Use data instead of marketMoveRun
      marketMoveMetaData?.productConfigList || []
    );
    const updatedProductConfigs = mergedProductConfigs.map((config) => ({
      ...config,
      marketMoveRunId: parseInt(marketMoveMetaData?.marketMoveRunId),
    }));
    const fieldsToUpdate: { name: keyof MarketMoveRunSchema; value: any }[] = [
      {
        name: "marketMoveRun",
        value: { ...marketMoveRun?.marketMoveRun, ...marketMoveMetaData },
      },
      { name: "marketMoveRunProductConfigs", value: updatedProductConfigs },
    ];

    fieldsToUpdate.forEach((field) => {
      marketMoveRunSetValue(field.name, field.value, { shouldDirty: true });
    });
  };

  return {
    isFetching,
    error,
  };
};
