import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Tooltip } from "@mui/material";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import React, { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IStore } from "../../../../interfaces/IStore";
import { MarketMoveMetaDataSchema } from "../../../../schema/schemas";
import useGeneralStore from "../../../../store/generalStore";
import { tableTheme } from "../../../../theme/theme";
import { listViewColumnDef } from "../../columns";
import StoreListDialog from "./StoreListDialog";
import StoreListTableToolbar from "./StoreListTableToolbar";
import { useStoreList } from "./useStoreList";
import { useFormStore } from "../../../../store/formStore";

type StoreWithInclusion = IStore & { includeFlag: boolean };

const StoreListTable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { elasticData } = useGeneralStore();
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({});

  const {
    handleToggle,
    workingElasticData,
    storeList,
    setValue,
    resetStoreList,
  } = useStoreList();
  const { marketMoveMetaDataMethods : {control}} = useFormStore()
  const currentMarketMove = useWatch<MarketMoveMetaDataSchema>({ control });

  const columns = useMemo<MRT_ColumnDef<StoreWithInclusion>[]>(
    () => [
      {
        accessorKey: "action",
        header: "Include",
        maxSize: 20,
        Cell: ({ row }) => {
          const include = row.original.includeFlag;
          return (
            <Tooltip title={include ? "Deselect" : "Select"}>
              <Box>
                {include ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </Box>
            </Tooltip>
          );
        },
        accessorFn: (row: any) => {
          console.debug("accessorFn", row);
          return row.includeFlag;
        },
        sortingFn: "basic",
      },
      ...listViewColumnDef,
    ],
    [storeList, elasticData, columnVisibility]
  );

  const table = useMaterialReactTable<StoreWithInclusion>({
    columns,
    data: workingElasticData,
    enableBottomToolbar: false,
    enableDensityToggle: false,
    enablePagination: false,
    enableRowSelection: false,
    enableMultiSort: true,
    enableRowVirtualization: true,
    enableGlobalFilter: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableCellActions: false,
    onRowSelectionChange: undefined,
    enableHiding: true,
    onColumnVisibilityChange: setColumnVisibility,
    columnFilterDisplayMode: "popover",
    layoutMode: "grid",
    ...tableTheme,
    initialState: {
      density: "compact",
      showColumnFilters: true,
      sorting: [{ id: "importcode", desc: true }],
    },
    state: {
      columnVisibility,
    },
    defaultColumn: {
      size: 50,
    },
    getRowId: (originalRow: StoreWithInclusion) => originalRow.importcode,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleToggle(row.original.importcode),
      sx: {
        cursor: "pointer",
      },
    }),
    renderTopToolbarCustomActions: () => (
      <StoreListTableToolbar
        allSelected={storeList.every((store) => store.includeFlag)}
        handleToggleAll={handleToggle}
        storeDataLength={elasticData.length}
      />
    ),
  });

  return currentMarketMove ? (
    <StoreListDialog
      storeList={storeList}
      open={open}
      resetStoreList={resetStoreList}
      onClose={() => {
        setValue("storeList", storeList);
      }}
      setOpen={setOpen}
      table={table}
    />
  ) : null;
};

export default StoreListTable;
